import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/fontawesome.css";
import "../assets/css/solid.css";
import "../assets/css/brands.css";
import "../assets/css/v4-shims.css";
import "../assets/css/v4-font-face.css";
import "../assets/css/animate.min.css";
import "../assets/css/fontello.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";

const Layout = props => {
  return <>{props.children}</>;
};

export default Layout;
